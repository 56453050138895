import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./inventory-manager.component";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    const logging = (message) => {
      console.log(`[@lotte-colour/inventory-manager] ${message}`);
    };

    logging(err);

    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
